(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("bootstrap-slider"), require("inputmask"), require("nouislider"), require("sortablejs"));
	else if(typeof define === 'function' && define.amd)
		define("surveyjs-widgets", ["bootstrap-slider", "inputmask", "nouislider", "sortablejs"], factory);
	else if(typeof exports === 'object')
		exports["surveyjs-widgets"] = factory(require("bootstrap-slider"), require("inputmask"), require("nouislider"), require("sortablejs"));
	else
		root["surveyjs-widgets"] = factory(root["Slider"], root["Inputmask"], root["noUiSlider"], root["Sortable"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_bootstrap_slider__, __WEBPACK_EXTERNAL_MODULE_inputmask__, __WEBPACK_EXTERNAL_MODULE_nouislider__, __WEBPACK_EXTERNAL_MODULE_sortablejs__) {
return 